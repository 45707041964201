import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

import { IconLinkedInColor, IconMediumColor, IconTwitterColor } from 'assets';

import { ROUTES } from '_constants';
import { drids } from '_constants/drids';

import { Page } from 'components/_shared/Page';

import { parseMarkdown, sortByKey } from 'utils';

import 'styles/PageCompany.styles.scss';

export default function PageCompany(props) {
  const {
    strapiAbout: page,
    strapiCompany: company,
    allStrapiEmployee: { nodes: employee },
    allStrapiInvestor: { nodes: investors }
  } = props.data;

  const people = sortByKey(employee, { equalKey: 'name' });
  const sortedInvestors = sortByKey(investors, { equalKey: 'investor_name' });

  const renderAbout = () => {
    return (
      <div className='w-full max-w-devrev-extra mx-auto flex flex-col pt-6 px-3 md:px-6 relative lg:pt-12 lg:flex-row'>
        <div className='lg:w-80 lg:mr-12 flex-shrink-0'>
          <div className='w-1/2 lg:w-5/6 text-3xl pb-12 md:pb-6 md:text-4xl lg:text-5xl leading-none'>
            <h2>The Builders of DevRev</h2>
          </div>
        </div>
        <div className='space-y-12 md:space-y-24'>
          <div className='leading-tight text-lg md:text-xl lg:text-2xl'>
            <div className='mb-3 md:mb-6'>
              {parseMarkdown(page.section1.data.section1)}
            </div>
            <div>{parseMarkdown(page.section2.data.section2)}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderPeople = () => {
    return (
      <section
        data-drid={drids.COMPANY_NAV.COMPANY_PEOPLE}
        className='w-full flex flex-col items-center pb-12 px-4 relative z-10 lg:px-12'
      >
        <div className='max-w-devrev-extra flex flex-wrap justify-center md:px-6 pt-10 lg:pt-16'>
          {people.map(person => (
            <div key={person.name} className='member-container'>
              {person.pic ? (
                <GatsbyImage
                  image={getImage(person.pic.localFile)}
                  alt={`${person.name} memoji`}
                />
              ) : (
                <StaticImage
                  src='../assets/images/memoji-default.png'
                  alt={`${person.name} memoji`}
                  placeholder='blurred'
                  quality={80}
                  width={512}
                />
              )}

              <h4 className='member-name'>{person.name}</h4>
              <h5 className='member-description'>{person.about.data.about}</h5>
              <div className='member-social-links'>
                {person.twitter && (
                  <a
                    className='twitter'
                    href={person.twitter}
                    title='Twitter'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <IconTwitterColor />
                  </a>
                )}
                {person.linkedin && (
                  <a
                    className='linkedin'
                    href={person.linkedin}
                    title='LinkedIn'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <IconLinkedInColor />
                  </a>
                )}
                {person.medium && (
                  <a
                    className='medium'
                    href={person.medium}
                    title='Medium'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <IconMediumColor />
                  </a>
                )}
              </div>
            </div>
          ))}
          <div className='member-container'>
            <StaticImage
              src='../assets/images/memoji-unicorn.png'
              alt='Unicorn - memoji'
              placeholder='blurred'
              quality={80}
              width={512}
            />
            <div className='member-name'>You!</div>
            <Link
              data-drid={drids.COMPANY_NAV.JOIN_US_BODY}
              className='cursor-pointer mt-2 inline-flex items-center border-05 border-transparent text-sm font-medium rounded-full text-white bg-gray-900 px-3 py-1.5 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-900'
              to={ROUTES.JOIN}
            >
              Join us →
            </Link>
          </div>
        </div>
      </section>
    );
  };

  const renderInvestors = () => {
    return (
      <div
        data-drid={drids.COMPANY_NAV.COMPANY_INVESTORS}
        className='md:px-6 pt-8 lg:pt-16 lg:flex w-full max-w-devrev-extra self-center'
      >
        <div className='mb-12 lg:mb-0 w-5/6 md:w-full text-3xl text-center md:text-4xl lg:text-5xl leading-none mx-auto lg:ml-0 lg:w-80 lg:mr-12 flex-shrink-0'>
          <h2 className='font-title md:text-left'>
            {company.investor_heading}
          </h2>
        </div>

        <div className='investors-list'>
          {sortedInvestors.map(investor => (
            <article
              key={investor.investor_name}
              className='investor top-hairline'
            >
              <header className='investor-header'>
                {investor.investor_image ? (
                  <GatsbyImage
                    className='investor-image'
                    image={getImage(investor.investor_image.localFile)}
                    alt={`${investor.investor_name} photo`}
                  />
                ) : (
                  <StaticImage
                    className='investor-image'
                    src='../assets/images/memoji-default.png'
                    alt={`${investor.investor_name} photo`}
                    placeholder='blurred'
                    quality={80}
                    width={512}
                  />
                )}
                <div className='investor-profile'>
                  {investor.investor_linkedin ? (
                    <a
                      href={investor.investor_linkedin}
                      title={`${investor.investor_name} LinkedIn`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <h4 className='investor-name'>
                        {investor.investor_name}
                      </h4>
                    </a>
                  ) : (
                    <h4 className='investor-name'>{investor.investor_name}</h4>
                  )}
                  {investor.investor_title && (
                    <h6 className='investor-position'>
                      {investor.investor_title}
                    </h6>
                  )}
                </div>
              </header>
              {investor.investor_story && (
                <div className='investor-text'>
                  {parseMarkdown(investor.investor_story.data.investor_story)}
                </div>
              )}
            </article>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Page
      className='page-company'
      meta={{
        title: company.seotitle,
        description: company.seodescription,
        image: company.preview_img[0]?.url
      }}
      drid={drids.PAGE.COMPANY}
    >
      <div className='header-spacer h-24' />
      {renderAbout()}
      {renderPeople()}
      {renderInvestors()}
    </Page>
  );
}

export const query = graphql`
  query PageCompanyQuery {
    allStrapiEmployee {
      nodes {
        about {
          data {
            about
          }
        }
        order
        name
        pic {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 512)
            }
          }
        }
      }
    }
    strapiAbout {
      ambition {
        data {
          ambition
        }
      }
      anti_fragility {
        data {
          anti_fragility
        }
      }
      attention_to_details {
        data {
          attention_to_details
        }
      }
      authenticity {
        data {
          authenticity
        }
      }
      our_values_desc {
        data {
          our_values_desc
        }
      }
      our_values_heading {
        data {
          our_values_heading
        }
      }
      section1 {
        data {
          section1
        }
      }
      section2 {
        data {
          section2
        }
      }
      section_3_desc {
        data {
          section_3_desc
        }
      }
      section_3_heading {
        data {
          section_3_heading
        }
      }
    }
    strapiCompany {
      seotitle
      seodescription
      people_heading
      investor_heading
      preview_img {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 80, width: 512)
          }
        }
      }
    }
    allStrapiInvestor {
      nodes {
        order
        investor_name
        investor_story {
          data {
            investor_story
          }
        }
        investor_title
        investor_linkedin
        investor_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 512)
            }
          }
        }
      }
    }
  }
`;
